
  import { Article } from '@/interfaces/arcticles/Article.entity';
  import { AuthenticationService } from '@/services/authentication.service';
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import CustomImage from '../CustomImage.component.vue';
  import ArticleDetailsImg from './ArticleDetailsImg.component.vue';
  import ArticleImgCarousel from './ArticleImgCarousel.component.vue';
  import ArticleSum from './ArticleSum.component.vue';

  /**
   * @desc composant affiachant la liste des images envoyées
   */
  @Component({
    components: {
      'article-details-img' : ArticleDetailsImg,
      'article-img-carousel' : ArticleImgCarousel
    },
  })
  export default class ArticleDetailsImgList extends Vue {
    @Prop({default: []}) 
    imgs? : Array<number>;

    imgSources = new Map<number, string>();

    @Watch('imgs')
    imageUpdate() {
      this.imgSources.clear();
    }

    /**
     * @desc ouvre la boite de dialogue de diaporama des images de la liste,
     * à partir de l'id selectionné
     * @param imgId : id de l'image de départ du diaporama
    */
    private showCarousel(imgId : number) : void {
      if(!this.imgs) return;

      let imgIndex = this.imgs.findIndex(x => x === imgId);
      if(imgIndex < 0 || imgIndex >= this.imgs.length) imgIndex = 0;

      this.$emit("openCarousel", this.imgs, imgIndex);
      this.$emit("updateImageSources", this.imgSources);
    }

    public imageLoaded(id: number, src: string): void {
      this.imgSources.set(id, src);
      this.$emit("updateImageSources", this.imgSources);
    }
  }
