
  import { Article } from '@/interfaces/arcticles/Article.entity';
  import { AuthenticationService } from '@/services/authentication.service';
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import CustomImage from '../CustomImage.component.vue';
  import ArticleSum from './ArticleSum.component.vue';

  /**
   * @desc boite de dialogue pour confirmer la quelque chose
   */
  @Component({
    components: {
      'c-img' : CustomImage
    },
  })
  export default class ArticleDetailsImg extends Vue {
    @Prop({default: 0})
    imgId?: number;

    /**
     * @desc envoie un événement dans les parents pour afficher l'image
     */
    private showImg() {
      this.$emit('showImg', this.imgId);
    }

    public imageLoaded(img: HTMLImageElement): void {
      if(!this.imgId || !img.src) return;
      this.$emit('imageLoaded', this.imgId, [img.src])
    }
  }
