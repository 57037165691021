
  import { Article } from '@/interfaces/arcticles/Article.entity';
  import * as UrlConsts from '@/scripts/UrlConsts';
  import * as Consts from '@/scripts/Consts';
  import { Utils } from '@/scripts/Utils';
  import { AuthenticationService } from '@/services/authentication.service';
  import axios from 'axios';
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import CustomImage from '../CustomImage.component.vue';
  import DialogBoxValidation from '../DialogBoxValidation.vue';
  import ArticleDetailsImgList from './ArticleDetailsImgList.component.vue';
  import ArticleImgCarousel from './ArticleImgCarousel.component.vue';
  import ArticleRubricList from './ArticleRubricList.component.vue';
  import { quillEditor } from 'vue-quill-editor'

  const textContentFormatSwitchDate = new Date("Thu Aug 18 2022 18:57:22 GMT+0200");

  /**
   * @desc composant permettant l'affichage de l'ensemble des données d'un article
   */
  @Component({
    components: {
      'c-img': CustomImage,
      quillEditor,
      'article-rubric-list' : ArticleRubricList,
      'article-details-img-list' : ArticleDetailsImgList,
      'article-img-carousel' : ArticleImgCarousel,
      'dialog-box-validation' : DialogBoxValidation
    },
  })
  export default class ArticleDetails extends Vue {

    articleContent = '';
    coverImgSrc = '';

    /**
     * @desc article à afficher
     */
    @Prop() 
    article? : Article;
    @Watch('article')
    private updateArticleContent(): void {
      if(this.article && this.article.textContent && this.article.createdAt) {
        if(this.article.createdAt > textContentFormatSwitchDate) { // quill format
          this.convertDeltaToHtmlText(this.article.textContent);
        }
        else {
          if(this.article.textContent.substring(0, 2) === '<p') {
            this.articleContent = this.article.textContent;
          }
          else { // quill format
            this.convertDeltaToHtmlText(this.article.textContent);
          }
        }
      }
    }

    private convertDeltaToHtmlText(strDelta: string): string {
      try {
        const delta = JSON.parse(strDelta);
        const quillEditor = this.$refs.quillEditor as any;
        if(!quillEditor) return '';
        quillEditor.quill.setContents(delta);
        setTimeout(() => {
          const htmlContainer = quillEditor.$el.querySelector('.ql-editor');
          this.articleContent = htmlContainer.innerHTML;
        });
      } catch(err) {
        console.log(err);
      }
      return '';
    }

    /**
     * @desc droits d'édition de l'article
     */
    editRights = false;

    /**
     * @desc événement de confirmation de la suppression d'un article via la DialogBoxValidation
     */
    deletionEventName = "articleDeleted";

    mounted() : void {
      this.editRights = AuthenticationService.getUserStatus() === Consts.user_status_teacher;

      this.updateArticleContent();
    }

    /**
     * @desc retourne un string contenant le nom de l'auteur et la date de création
     */
    private getArticleDetails(authorName : string, createdAt : Date) : string {
      if(!authorName.length) authorName = 'inconnu';
      if(!createdAt) createdAt = new Date();

      let date = createdAt.getDate().toString();
      if(date.length === 1) date = '0' + date;
      let month = (createdAt.getMonth()+1).toString();
      if(month.length === 1) month = '0' + month;
      const strDate = date + '/' + month + "/" + createdAt.getFullYear();

      let outputTxt = 'Rédigé par ' + authorName;
      outputTxt += " le " + strDate;

      return outputTxt;
    }

    public coverImgLoaded(img: HTMLImageElement): void {
      this.coverImgSrc = img.src;
    }

    /**
     * @desc montre l'image de couverture de l'article
     */
    private showCoverImg() : void {
      if(!this.coverImgSrc) return;

      const articleImageCarousel = this.$refs.articleImageCarousel as ArticleImgCarousel;
      if(!articleImageCarousel) return;
      (articleImageCarousel).showCoverImg(this.coverImgSrc);
    }

    public showImageList(imgs : Array<number>, imgIndex : number): void {
      const articleImageCarousel = this.$refs.articleImageCarousel as ArticleImgCarousel;
      if(!articleImageCarousel) return;
      (articleImageCarousel).show(imgs, imgIndex);
    }

    public updateImageSources(imgSources : Map<number, string>): void {
      const articleImageCarousel = this.$refs.articleImageCarousel as ArticleImgCarousel;
      if(!articleImageCarousel) return;
      (articleImageCarousel).updateSources(imgSources);
    }


    /**
     * @desc envoie vers la page d'édition de l'article
     */
    private editArticle() : void {
      if(!this.article) return;
      this.$router.push('/article/edition/' + this.article.id);
    }

    /**
     * @desc ouvre la boite de dialogue de validation de la suppression
     */
    private deleteArticle() : void {
      (this.$refs.deletionDialogBox as DialogBoxValidation).show();
    }

    /**
     * @desc envoie la requète server de suppression de l'article,
     * puis envoie vers la page de confirmation de la suppression
     */
    private serverDeleteArticle() : void {
      if(!this.article) return;
      const headers = AuthenticationService.getRequestHeader();
      
      axios
        .delete(  UrlConsts.deleteArticle + '/' + this.article.id, headers)
        .then(() => {
          const query = Utils.createUrlQuery({type:"article-deletion"});
          this.$router.push('/validation?' + query);
        })
        .catch(error => console.log(error))
    }
  }

