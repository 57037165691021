
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import Container from '../Container.component.vue';
  import CustomImage from '../CustomImage.component.vue';


  /**
   * @desc composant permettant d'afficher un diaporama des images envoyées
   */
  @Component({
    components: {
      'c-img': CustomImage,
      'container' : Container
    },
  })
  export default class ArticleImgCarousel extends Vue {
    boxWidth = "72rem";
    visible = false;

    /**
     * @desc images à afficher
     */
    imgs : Array<number> = [];

    imgSources = new Map<number, string>();

    /**
     * @desc image actuellement affichée
     */
    imgIndex = 0;

    loading = false;
    coverImgSrc = '';

    public carouselImgLoaded(imgEl : HTMLImageElement): void {
        this.loading = false;
        this.updateView(imgEl);
    }

    /**
     * @desc ouvre la boite de dialogue du diaporama
     * @param imgs les images à afficher
     * @param initialImg l'index dans le tableau de l'image à afficher
     */
    show(imgs: Array<number>, initialImg: number) : void {
      this.visible = true;
      this.$vuetify.theme.dark = false;
      this.coverImgSrc = '';

      if(this.arraysEqual(this.imgs, imgs) && this.imgIndex === initialImg && (this.imgSources.has(this.imgs[this.imgIndex]))) {
        this.loading = false;
      }
      else{
        this.loading = true;
        this.imgs = imgs;
        this.imgIndex = initialImg;
      }
      this.init();
    }

    showCoverImg(src: string): void {
      this.visible = true;
      this.$vuetify.theme.dark = false;

      if(this.coverImgSrc === src) {
        this.loading = false;
      }
      else{
        this.loading = true;
        this.coverImgSrc = src;
      }
      this.init();
    }

    public updateSources(imgSources: Map<number, string>): void {
      this.imgSources = imgSources;
      this.$forceUpdate();
    }

    /**
     * @desc vérifie si deux tableaux contiennent les mêmes éléments
     */
    private arraysEqual(a : Array<any>, b : Array<any>) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    }

    /**
     * @desc initialtisation de l'écoute des événements
     */
    init() : void {
      // click en dehors de la boite de dialogue
      const backgroundDialog = (this.$refs.container as Container)?.$el as HTMLElement;
      if(!backgroundDialog) return;
      backgroundDialog.parentNode?.addEventListener("click", (event: Event) => {
        if((event.target as Element).querySelector(".background-dialog")) {
          this.close();
        }
      });
    }

    /**
     * @desc charge et affiche l'image suivante
     */
    next() : void  {
      this.editIndex(1);
    }

    /**
     * @desc charge et affiche l'image précédente
     */
    previous() : void  {
      this.editIndex(-1);
    }

    /**
     * @desc modifie l'index de l'image à afficher
     * @param nbToAdd nombre ajouté à l'index actuel
     */
    editIndex(nbToAdd : number) : void {
      if(this.imgs.length < 2) return;

      let newIndex = this.imgIndex + nbToAdd;
      if(newIndex >= this.imgs.length) {
        newIndex = newIndex % this.imgs.length;
      }
      else if(newIndex < 0) {
        newIndex = this.imgs.length + (newIndex%this.imgs.length);
      }
      this.imgIndex = newIndex;
      this.loading = true;
    }

    /**
     * @desc met à jour la taille de l'image affichée à l'écran
     * @param imgEl l'élément image associé au carousel
     */
    updateView(imgEl : HTMLImageElement) : void {
      if(!imgEl) return;

      // contenant du contenu de la boite de dialogue
      const container = (this.$refs.container as Container)?.$el as HTMLElement;
      if(!container) return;

      // boite contenant l'élément image
      const carouselImgBox = (this.$refs.carouselImgBox as Container)?.$el as HTMLElement;

      // maximise la taille d'affichage de l'image dans la fenetre
      container.style.width = "100%";
      carouselImgBox.style.width = "100%";
      imgEl.style.width = "100%";

      // récupère les dimensions réeles et affichées de l'image
      const displayedW = imgEl.clientWidth;
      const imgW = imgEl.naturalWidth;
      const displayedH = imgEl.clientHeight;
      const imgH = imgEl.naturalHeight;

      // en fait les rapports
      const ratioW = imgW / displayedW;
      const ratioH = imgH / displayedH;

      // l'image est plus rapeticée en hauteur qu'en largeur
      if(ratioH > ratioW) {
        // la largeur de l'image est adaptée à sa hauteur
        imgEl.style.width = (imgW / ratioH).toString() + "px";
        carouselImgBox.style.width = "unset";
      }
      else{
        imgEl.style.width = "100%";
      }
      container.style.width = imgEl.clientWidth + "px";
    }

    /**
     * @desc ferme la boite de dialogue
     */
    close() : void{
      this.visible = false;
    }

    getCustomSrc(): string | undefined {
      if(this.coverImgSrc) return this.coverImgSrc;
      return this.imgSources.has(this.imgs[this.imgIndex]) ? this.imgSources.get(this.imgs[this.imgIndex]) : ''
    }
  }


